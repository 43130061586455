<template>
    <modal ref="modalCalificarServicio" tamano="modal-lg" titulo="Calificar servicio" adicional="Calificar" @adicional="calificar">
        <div class="row justify-content-center m-3 f-15">
            <p class="col-12 text-center f-12">Califica el servicio solicitado al <span class="f-600" v-if="obj">{{ obj.taller }}</span> en los siguientes aspectos:</p>
        </div>
        <div class="listado-preguntas">
			<ValidationObserver ref="validator">
				<div v-for="(data,idx) in questions_service" :key="idx" class="row pregunta border-bottom mx-3 py-3">
					<li class="col-12 text-general f-12 mb-2 ucfirst f-600">{{ data.nombre }}</li>
					<div v-if="data.id_tipo === 5" class="col-12">
						<ValidationProvider v-slot="{ errors }" :name="`${data.nombre}`" rules="required">
							<p class="input-label-top">Selecciona una opción</p>
							<el-select v-model="data.vmodel" size="small">
								<el-option v-for="(item,index) in data.opciones" :key="index" :value="[item.id,item.valor]" :label="item.opcion" />
							</el-select>
							<vee-error :text="errors[0]"></vee-error>
						</ValidationProvider>
					</div>
					<div v-if="data.id_tipo === 6" class="col-12 my-2">
						<ValidationProvider v-slot="{ errors }" :name="`${idx+10}`" rules="required">
							<p class="input-label-top">Calificar</p>
							<el-rate v-model="data.vmodel" show-score  :colors="['#FF9800','#FF9800','#FF9800']" text-color="#FF9800" score-template="{value}" void-color="#C0C4CC" disabled-void-color="#C0C4CC" />
							<vee-error :text="errors[0]"></vee-error>
						</ValidationProvider>
					</div>
				</div>
			</ValidationObserver>
        </div>
    </modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
    data(){
        return {
            model:{
                pregunta: ''
            },
			sinPrueguntas: false,
            obj: null,
        }
    },
    computed: {
        ...mapGetters({
            questions_service: 'obras/servicios/services/questions_service',
			detalleServicio: 'obras/servicios/lista_chequeo/detalleServicio',
        }),
        id_taller(){
            return this.$route.params.id_control_piso
        },
        id_proyecto(){
            return this.$route.params.id_proyecto
        },
    },
    methods: {
        ...mapActions({
            Action_get_aspectos_calificar: 'obras/servicios/services/Action_get_aspectos_calificar',
            Action_finalizar_servicio: 'obras/servicios/services/Action_finalizar_servicio',
        }),
        async toggle(info){
            if (info) this.obj = info
            await this.getAspectos()
			if(!this.questions_service.length) {
				this.sinPrueguntas = true
				this.calificar()
				return
			}
			this.sinPrueguntas = false
            this.$refs.modalCalificarServicio.toggle()
        },
        async getAspectos(){
            await this.Action_get_aspectos_calificar({id_taller: this.detalleServicio.id_taller})
        },
        async calificar(){
			const validado = await this.$refs.validator.validate()
			if(!validado) return
            this.obj.id_proyecto = this.id_proyecto
            this.obj.id_control_piso = this.id_taller
            let dataCalificaciones= [];
            this.questions_service.forEach(e => {
                if (e.id_tipo == 5) {
                    dataCalificaciones.push(
                        {
                            id_configuracion_control_piso_aspectos_calificar_talleres: e.id,
                            valor: e.vmodel[1],
                            id_configuracion_control_piso_aspectos_calificar_opciones: e.vmodel[0] ,
                        }
                    ) 
                } else {
                    dataCalificaciones.push(
                        {
                            id_configuracion_control_piso_aspectos_calificar_talleres: e.id,
                            valor: e.vmodel,
                            id_configuracion_control_piso_aspectos_calificar_opciones: null
                        }
                    ) 
                }
                
            });
            this.obj.calificacion_servicio = dataCalificaciones
            await this.Action_finalizar_servicio(this.obj)
            this.$emit('end')
            if(!this.sinPrueguntas) this.$refs.modalCalificarServicio.toggle()
        }
    }
}
</script>

<style lang="css" scoped>
</style>
