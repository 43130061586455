<template>
    <section class="obras-ver-servicio px-3">
        <navbar-admin titulo="Obras" >
            <el-tooltip content="Editar servicio" placement="bottom" effect="light" visible-arrow>
                <button v-if="detalleServicio.estado == 15" class="btn btn-square32-5d ml-2" @click="editarServicio(detalleServicio)">
                    <i class="icon-pencil-outline text-white" />
                </button>
            </el-tooltip>
            <el-tooltip content="Eliminar servicio" placement="bottom-end" effect="light" visible-arrow>
                <button class="btn btn-square32-5d mx-2" @click="eliminarServicio">
                    <i class="icon-trash-can-outline text-white" />
                </button>
            </el-tooltip>
            <button v-if="detalleServicio.estado == 15" class="btn btn-general f-12" @click="finalirServicio(detalleServicio)">
                Finalizar servicio
            </button>
        </navbar-admin>
        <div class="row my-4">
            <div class="col-9">
                <div class="bg-f5 br-5 p-2">
                    <div class="row mx-0">
                        <div class="col-auto">
                            <p class="f-16 f-600 tres-puntos">{{ detalleServicio.seccion || 'Sin nombre' }}</p>
                        </div>
                        <div class="col-auto ml-auto">
                            <p class="f-500 f-14">
                                Taller:
                                <span class="f-300">{{ detalleServicio.taller || 'Sin taller' }}</span>
                            </p> 
                        </div>
                        <div class="col-auto">
                            <p class="f-500 f-14">
                                Configuración:
                                <span class="f-300">{{ detalleServicio.configuracion || 'Sin configuración' }}</span>
                            </p> 
                        </div>
                    </div>
                    <div class="row mx-0 mt-2">
                        <p class="col-12 f-12 lh-17">{{ detalleServicio.descripcion || '' }}</p>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <el-tooltip :content="`Porcentaje de avance ${porcentajeeee || 0}%`" placement="bottom" effect="light" visible-arrow>
                    <div  class="border br-4 h-37px position-relative d-middle-center bg-cont">
                        <div v-if="detalleServicio && porcentajeeee > 0" class="bg-general h-37px br-4 f-600 d-middle-center position-absolute" :style="`width: ${porcentajeeee}%; left: 0px;`">
                        </div>
                        <p class="z-1 text-white">{{ porcentajeeee }}%</p>
                        <!-- <div v-else class="bg-f5 h-37px br-4 text-muted f-600 d-middle-center" style="width: 100%">
                            Sin avance
                        </div> -->
                    </div>
                </el-tooltip>
            </div>
        </div>
        <tabs :tabs="tabsServiciosVer" class="mb-4" />
        <router-view />
        <!-- Partials -->
        <modal-eliminar ref="abrirModalEliminarServicio" title="Eliminar servicio" mensaje="¿Está seguro que quiere eliminar este servicio?" @delete="handleDeleteService" />
        <modal-edit-servicio ref="abrirEditarServicio" @reload="getDetalleServicio" />
        <modal-finalizar-servicio ref="abrirFinalizarServicio" @calificar="openCalificarServicio" />
        <modal-finalizar-calificar-servicio ref="abrirFinalizarCalificarServicio" @end="finalizarServicioFinal" />
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import modalFinalizarCalificarServicio from '../partials/modalFinalizarCalificarServicio.vue'
export default {
    components:{
        modalEditServicio:() => import('./partials/modalEditServicio.vue'),
        modalFinalizarServicio:() => import('../partials/modalFinalizarServicio.vue'),
        modalFinalizarCalificarServicio,
    },
    data(){
        return{
            tabsServiciosVer: [
                {
                    titulo:'Lista de chequeo',
                    ruta:"obras.vigentes.lista.chequeo",
                },
                {
                    titulo:'Visitas',
                    ruta:"obras.vigentes.servicios.visitas",
                },
                {
                    titulo:'Documentación',
                    ruta:"obras.vigentes.servicios.documentacion",
                },
                {
                    titulo:'Orden de compra',
                    ruta:"obras.vigentes.orden.compra",
                },
                {
                    titulo:'Calendario',
                    ruta:"obras.vigentes.calendario",
                },
                {
                    titulo:'Forma de pago',
                    ruta:"obras.vigentes.forma.pago",
                },
            ],
            porcentajeeee: 0
        }
    },
    computed: {
        ...mapGetters({
            select_talleres: 'selects/selects/select_talleres',
            select_configuraciones: 'selects/selects/select_configuraciones',
            select_configuraciones_secciones: 'selects/selects/select_configuraciones_secciones',
            detalleObra: 'obras/informacion/informacion',
            detalleServicio: 'obras/servicios/lista_chequeo/detalleServicio',
        }),
        id_proyecto(){
            return this.$route.params.id_proyecto
        },
        id_control_piso(){
            return this.$route.params.id_control_piso
        },
    },
    watch: {
        "$route.name"(val){
            this.replaceBreadcumb({ position: 3, breadcumb: val })
        }
    },
    async created(){
        this.getBreadcumbs(['obras.main'])
        await Promise.all([
            this.getDetalleServicio(),
            //this.Action_get_detalle_servicio({id_proyecto: this.id_proyecto, id_control_piso: this.id_control_piso}),
            this.Action_get_informacion_header(this.id_proyecto),
        ])
        this.spliceBreadcumbs([
            {
                position: 1,
                breadcumb: {
                    name: this.detalleObra.nombre,
                    route: 'obras.vigentes.ver.servicios',
                    params: { id: this.detalleObra.id }
                }
            },
            {
                position: 2,
                breadcumb: {
                    name: this.detalleServicio.seccion,
                    route: 'obras.vigentes',
                    params: {
                        id_proyecto: this.id_proyecto,
                        id_control_piso: this.id_control_piso,
                    }
                },
            }
        ])
        this.replaceBreadcumb({ position: 3, breadcumb: this.$route.name })
        await this.listarSelects()
        this.porcentajeeee = this.detalleServicio.porcentaje_avance
    },
    methods:{
        ...mapActions({
            Action_get_selects_talleres: 'selects/selects/Action_get_selects_talleres',
            Action_get_selects_configuraciones: 'selects/selects/Action_get_selects_configuraciones',
            Action_get_selects_configuraciones_secciones: 'selects/selects/Action_get_selects_configuraciones_secciones',

            Action_get_informacion_header: 'obras/informacion/Action_get_informacion_header',
            Action_get_detalle_servicio: 'obras/servicios/lista_chequeo/Action_get_detalle_servicio',
            spliceBreadcumbs: 'navigation/breadcumbs/spliceBreadcumbs',
            replaceBreadcumb: 'navigation/breadcumbs/replaceBreadcumb',
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            Action_delete_servicio: 'obras/servicios/services/Action_delete_servicio',
        }),
        async getDetalleServicio(){
            await this.Action_get_detalle_servicio({id_proyecto: this.id_proyecto, id_control_piso: this.id_control_piso})
        },
        finalirServicio(data){
            this.$refs.abrirFinalizarServicio.toggle(data)
        },
        editarServicio(data){
            this.$refs.abrirEditarServicio.toggle(data)
        },
        eliminarServicio(){
            this.$refs.abrirModalEliminarServicio.toggle()
        },
        async handleDeleteService(){
            await this.Action_delete_servicio({id:this.id_control_piso})
            this.$router.push({ name: 'obras.vigentes.ver.servicios', params: {id: this.id_proyecto} })
        },
        async listarSelects(){
            await this.Action_get_selects_talleres()
            await this.Action_get_selects_configuraciones()
        },
        openCalificarServicio(info){
            this.$refs.abrirFinalizarCalificarServicio.toggle(info)
        },
        finalizarServicioFinal(){
            this.$router.push({ name: 'obras.vigentes.ver.servicios', params: {id: this.id_proyecto} })
        }
    }

}
</script>

<style lang="scss" scoped>
.h-37px{
    height: 37px;
}
.bg-cont{
    background: #0b56a782;
}
</style>